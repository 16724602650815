@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  src: url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
.project-image {
  max-width: 200px;  /* Adjust this value as needed */
  height: auto;
  object-fit: contain;
  display: block;  /* Added for centering */
  margin: 0 auto;  /* Added for centering */
  padding: 20px 0; /* Added some vertical spacing */
}
/* Add or update these styles */
.skills-list {
  padding-left: 0;
  list-style: none;
}

.skills-list li {
  font-size: 1.2rem;
  line-height: 1.4;
  color: inherit;
  margin-bottom: 0.3rem;
  text-align: left;
}

.skills-subsection-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
}

.skills-company {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: left;
}
