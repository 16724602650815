@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: $textColorDark;
}

// Add responsive container styles
.main-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  overflow-x: hidden; // Prevent horizontal scroll
  
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

// Ensure sections are visible on mobile
section {
  width: 100%;
  position: relative;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    min-height: auto;
    padding: 1rem 0;
  }
}
