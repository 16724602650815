@import "../../_globalColor";

.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.skills-text-div {
  margin-left: 50px;
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}
.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
}

.skills-subsection {
  margin-bottom: 30px;
}

.skills-subsection-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.skills-company {
  font-size: 18px;
  margin-bottom: 15px;
  
  a {
    color: $buttonColor;
    text-decoration: none;
    transition: all 0.3s ease;
    
    &:hover {
      opacity: 0.8;
    }
    
    .dark-mode & {
      color: $textColorDark;
      
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.skills-list {
  list-style-type: none;
  padding-left: 0;
  
  a {
    color: $buttonColor;
    text-decoration: none;
    transition: all 0.3s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }
  
  .dark-mode & a {
    color: $textColorDark;
    
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .skills-subsection-title {
    font-size: 20px;
    text-align: center;
  }

  .skills-company {
    font-size: 16px;
    text-align: center;
  }

  .skills-list {
    text-align: center;
  }
}
