@import "../../_globalColor";

.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
}

.icon-button i {
  color: $textColorDark;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

.facebook i {
  background-color: $faceBook;
}

.linkedin i {
  background-color: $linkedin;
}

.github i {
  background-color: $github;
}

.gitlab i {
  background-color: $gitlab;
}

.google i {
  background-color: $google;
}

.twitter i {
  background-color: #000000;
  color: #ffffff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  transform: scale(1);
}

.medium i {
  background-color: $medium;
}

.stack-overflow i {
  background-color: $stackoverflow;
}

.kaggle i {
  background-color: $kaggle;
}

.instagram i {
  background-color: $instagram;
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
  background-color: $textColor;
}

/* Hover Transition */
.twitter i:hover {
  background-color: #333333;
  color: #ffffff !important;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}

a {
  text-decoration: none !important;
}
