@import "../../_globalColor";

.experience-card {
  position: relative;
  background-color: $lightBackground2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
}

// Override the default banner background for ApplauseLab
.experience-card[data-company="ApplauseLab"],
.experience-card-dark[data-company="ApplauseLab"] {
  .experience-banner {
    background: #40C463 !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)) !important;
  }

  .experience-blurred_div {
    background: linear-gradient(rgba(64, 196, 99, 0.6), rgba(64, 196, 99, 0.4)) !important;
  }
}

// Additional dark mode specific adjustments
.experience-card-dark[data-company="ApplauseLab"] {
  .experience-banner {
    background: #40C463 !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)) !important;
  }

  .experience-blurred_div {
    background: linear-gradient(rgba(64, 196, 99, 0.7), rgba(64, 196, 99, 0.5)) !important;
  }
}

.experience-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.experience-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
  background: $experienceCardBannerLG;
  border-radius: 10px 10px 0px 0px;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  height: 10rem;
  position: relative;
}

.experience-blurred_div {
  position: absolute;
  background: $experienceCardBlurredDivLG;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  height: 10rem;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.experience-div-company {
  position: absolute;
  background: transparent;
  height: 8rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.experience-text-company {
  text-align: center;
  padding: 1rem;
  margin: 0;
  color: $lightBackground1;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.experience-roundedimg {
  position: absolute;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 6rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: white;
  padding: 1rem;
}

.experience-text-role {
  text-align: center;
  color: $textColor;
  font-weight: 700;
  font-size: 25px;
  margin: 0px;
  padding-top: 5.5rem;
  line-height: normal;
}

.dark-mode-text {
  color: $textColorDark !important;
}

.experience-text-date {
  text-align: center;
  color: $textColor;
  font-size: 20px;
  margin: 0px;
  padding-top: 1rem;
  font-weight: 600;
}

.experience-text-desc {
  text-align: center;
}

.experience-text-details {
  padding: 1.5rem;
  margin-top: 2rem;
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-roundedimg {
    width: 7rem;
    height: 7rem;
    top: 5.5rem;
    padding: 0.8rem;
  }
  .experience-text-role {
    padding-top: 5rem;
  }
  .experience-text-company {
    font-size: 20px;
  }
  .experience-text-date {
    font-size: 18px;
  }
  .experience-roundedimg {
    width: 6.5rem;
    height: 6.5rem;
    top: 6.5rem;
  }
}

@media (max-width: 768px) {
  .experience-roundedimg {
    width: 6rem;
    height: 6rem;
    top: 5rem;
    padding: 0.7rem;
  }
  .experience-text-role {
    padding-top: 4.5rem;
  }
  .experience-text-company {
    font-size: 18px;
  }
  .experience-text-date {
    font-size: 16px;
  }
  .experience-text-desc {
    font-size: 16px;
  }
  .experience-roundedimg {
    width: 5rem;
    height: 5rem;
    top: 6rem;
  }
}

.experience-card-dark {
  position: relative;
  background-color: $darkBackground;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
}

.experience-card-dark:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}
