.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem;
  padding: 20px;
}

.experience-heading {
  font-size: 56px;
  font-weight: 400;
}

.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.experience-container > * {
  flex: 1;
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .experience-container {
    flex-direction: column;
    padding: 10px;
    margin-top: 1rem;
    width: 100%;
  }

  .experience-cards-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    width: 100%;
  }

  .experience-heading {
    font-size: 30px;
    text-align: center;
    margin-bottom: 1rem;
  }

  .experience-card, .experience-card-dark {
    width: 100%;
    margin: 10px 0;
  }
}
